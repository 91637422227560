@import '../../Styles/theme';
$delivered: theme-color('primary', 'light');
$failed: theme-color('error', 'base');
$read: theme-color('success', 'secondary');

.message-status-description {
  .delivered {
    text-transform: uppercase;
    color: $delivered;
  }
  .read {
    text-transform: uppercase;
    color: $read;
  }
  .failed {
    text-transform: uppercase;
    color: $failed;
  }
}
.message-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    &.delivered {
      color: $delivered;
      text-transform: uppercase;
    }
    &.read {
      color: $read;
      text-transform: uppercase;
    }
    &.failed {
      color: $failed;
      text-transform: uppercase;
    }
    .MuiSvgIcon-root {
      height: 0.8em;
    }
  }
}
